:root {
  // Purple – Primary
  --purple1000: #1b1534;
  --purple1000-rgb: 27, 21, 52;
  --purple900: #361358;
  --purple900-rgb: 54, 19, 88;
  --purple800: #41176b;
  --purple800-rgb: 65, 23, 107;
  --purple700: #4c1b7e;
  --purple700-rgb: 76, 27, 126;
  --purple600: #5e328b;
  --purple600-rgb: 94, 50, 139;
  --purple500: #704998;
  --purple500-rgb: 112, 73, 152;
  --purple400: #825fa5;
  --purple400-rgb: 130, 95, 165;
  --purple300: #9476b2;
  --purple300-rgb: 148, 118, 178;
  --purple200: #a68dbe;
  --purple200-rgb: 166, 141, 190;
  --purple100: #b8a4cb;
  --purple100-rgb: 184, 164, 203;
  --purple50: #c9bbd8;
  --purple50-rgb: 201, 187, 216;

  // Petrol – Secondary
  --darkpetrol: #2d373c;
  --petrol900: #009999;
  --petrol800: #17a2a2;
  --petrol700: #2eacac;
  --petrol600: #46b5b5;
  --petrol500: #5dbebe;
  --petrol400: #74c7c7;
  --petrol300: #8bd1d1;
  --petrol200: #a3d9d9;
  --petrol100: #bbe2e2;
  --petrol50: #d2ecec;

  // Dark Grey
  --darkgrey900: #1b1534;
  --darkgrey800: #36314c;
  --darkgrey700: #514c66;
  --darkgrey600: #6b6680;
  --darkgrey500: #878299;
  --darkgrey400: #a39fb0;
  --darkgrey300: #bdbcc8;

  // Light Grey
  --lightgrey600: #a9b9d1;
  --lightgrey500: #b5c4d9;
  --lightgrey400: #c3d0e2;
  --lightgrey300: #d0dae9;
  --lightgrey200: #dee4ef;
  --lightgrey100: #ebeef5;
  --lightgrey50: #f8f8fc;

  // Extra Colors
  --dark-blue: #000028;
  --grey-blue: #507486;
  --blue600: #0066cc;
  --blue600-rgb: 0, 102, 204;
  --blue500: #408cd9;
  --blue500-rgb: 64, 140, 217;
  --blue400: #7eb3e8;
  --blue400-rgb: 126, 179, 232;
  --orange600: #ff8c1a;
  --orange600-rgb: 255, 140, 26;
  --orange500: #ffaa33;
  --orange500-rgb: 255, 170, 51;
  --orange400: #ffc470;
  --orange400-rgb: 255, 196, 112;
  --red600: #b81430;
  --red600-rgb: 184, 20, 48;
  --red500: #e51a3c;
  --red500-rgb: 229, 26, 60;
  --red400: #ed5e76;
  --red400-rgb: 237, 94, 118;
  --green600: #29a347;
  --green600-rgb: 41, 163, 71;
  --green500: #2fbc52;
  --green500-rgb: 47, 188, 82;
  --green400: #69d377;
  --green400-rgb: 105, 211, 119;

  // Preset Heights
  --header-height: 65px;
  --sub-header-height: 100px;
  --viewport-height: 100vh;
  --footer-height: 40px;
  --button-row: 64px;
  --table-button-height: 62px;
  --sub-nav-bar-height: 64px;
  --upload-field-height: 63px;

  // Header Colors
  --header-overlay-title: rgb(208, 218, 232);
  --header-overlay: rgb(222, 228, 239);
  --header-overlay-border: rgb(222, 228, 239);
  --header-overlay-border-contrast: rgb(204, 237, 236);

  --header-overlay-title-contrast: rgb(177, 227, 227);
  --header-overlay-contrast: rgb(204, 237, 236);
}

// New Siemens COIN Colors

// // custom
// $se-task -> $blue600
// $se-news -> $darkgrey600
// $se-announcement -> $petrol900
// $se-task-half -> $blue400
// $se-news-half -> $darkgrey300
// $se-announcement-half -> $petrol400

// // general
// $se-base -> $purple700
// $se-base-dark -> $darkgrey800
// $se-base-darker -> $darkgrey900
// $se-tint -> $purple500
// $se-light -> $purple600
// $se-dark -> $darkgrey900

// //gray
// $se-gray -> $lightgrey200
// $se-gray-contrast -> $lightgrey200
// $se-gray-dark -> $lightgrey400
// $se-gray-dark-transparent -> $lightgrey300
// $se-darker-gray -> $lightgrey600
// $se-darker-gray-transparent -> $lightgrey500
// $se-light-gray -> $lightgrey100

// // half
// $se-base-rgb-half -> $purple200
// $se-light-rgb-half -> $purple300
// $se-dark-rgb-half -> $darkgrey500
// $se-icon-rgb-half -> $darkgrey400

// // element specific
// $se-border -> $purple800
// $se-icon -> $purple900
// $se-button-default -> $purple700
// $se-button-active -> $purple900
// $se-button-focus -> $purple800

// // COLORS
// $white
// $black
// $black-light -> $darkgrey800
// $black-extra-light -> $darkgrey700

// $font-color -> $darkgrey900
// $font-color-grey-dark -> $darkgrey700
// $font-color-grey -> $darkgrey500
// $font-color-grey-extra-light -> $lightgrey400
// $font-color-grey-super-extra-light -> $lightgrey100
// $input-border -> $lightgrey300
// $input-border-top -> $darkgrey400
// $font-color-calc-value -> $darkgrey900

// $nav-light -> $lightgrey500

// $bg-survey-1 -> $lightgrey100
// $bg-survey-2 -> $lightgrey200

// $quiz-blue -> $lightgrey100
// $quiz-blue-dark -> $purple800

// $tile-border -> $lightgrey100

// $info-color -> $lightgrey600

// $logo-color -> $petrol700

// $petrol-color -> $petrol900

// $green-chart -> $green600
// $red-chart -> $red500
// $blue-chart -> $blue400
// $blue-form -> $purple800

// $green-managing-tasks -> $green500
// $blue-managing-tasks -> $blue400

// $link-color -> $purple600
// $link-color-hover -> $purple800

// $bg-grey -> $darkgrey700
// $bg-grey-light -> $lightgrey100
// $bg-academy-interactive -> $lightgrey50

// $button-inactive -> $darkgrey200

// $grey -> $darkgrey700
// $grey-light -> $darkgrey500

// $orange -> $orange500
// $orange-dark -> $orange600

// $blue -> $blue500
// $blue-dark -> $blue600

// $green -> $green600
// $red -> $red600
// $navigation-active -> $purple800

// $input-error -> $red500
// $video-bg -> $lightgrey100

// $academy-icon-hover -> $purple700

// $nav-bottom-line -> $lightgrey200
// $tooltip-underline -> $darkgrey400
// $input-color-disabled -> $darkgrey400
// $input-bg-disabled -> $lightgrey200

// $document-border -> $darkgrey600
// $checkbox-border-active -> $purple800
// $doc-repo-hover -> $blue400
// $color-sps-hover -> $red600

// $notification-orange -> $orange600

// $new-bg -> $darkgrey800

// $circle-loader-outline -> $lightgrey400
