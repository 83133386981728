:root {
  // old design primary
  // --ion-color-primary: #0088ae;
  // --ion-color-primary-rgb: 0, 136, 174;
  // --ion-color-primary-contrast: #ffffff;
  // --ion-color-primary-contrast-rgb: 255, 255, 255;
  // --ion-color-primary-shade: #007899;
  // --ion-color-primary-tint: #1a94b6;

  --ion-color-primary: var(--purple700);
  --ion-color-primary-light: var(--purple600);
  --ion-color-primary-dark: var(--darkgrey900);
  --ion-color-primary-tint: var(--purple500);

  --ion-color-yellow: #ffb700;
  --ion-color-yellow-2: #ffa200;
  --ion-color-yellow-3: #ff8700;
  --ion-color-yellow-4: #fb7100;
  --ion-color-yellow-rgb: 255, 183, 0;
  --ion-color-yellow-contrast: #000000;
  --ion-color-yellow-contrast-rgb: 0, 0, 0;
  --ion-color-yellow-shade: #e0a100;
  --ion-color-yellow-tint: #ffbe1a;

  --ion-color-gray: #dee6ee;
  --ion-color-gray-2: #cad9e2;
  --ion-color-gray-3: #758792;
  --ion-color-gray-rgb: 146, 148, 156;
  --ion-color-gray-contrast: #ffffff;
  --ion-color-gray-contrast-rgb: 255, 255, 255;
  --ion-color-gray-shade: #808289;
  --ion-color-gray-tint: #9d9fa6;

  --ion-color-secondary: #00a3a1;
  --ion-color-secondary-rgb: 0, 163, 161;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #008f8e;
  --ion-color-secondary-tint: #1aacaa;

  --ion-color-tertiary: #97afc0;
  --ion-color-tertiary-rgb: 151, 175, 192;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #859aa9;
  --ion-color-tertiary-tint: #a1b7c6;

  --ion-color-success: #a1ff7a;
  --ion-color-success-rgb: 161, 255, 122;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #8ee06b;
  --ion-color-success-tint: #aaff87;

  --ion-color-danger: #f00000;
  --ion-color-danger-2: crimson;
  --ion-color-danger-3: darkred;
  --ion-color-danger-rgb: 240, 0, 0;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d30000;
  --ion-color-danger-tint: #f21a1a;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** spacing **/
  --ion-margin: 30px;
  --ion-padding: 20px;
  --ion-box-shadow-default-color: rgba(48, 50, 50, 0.3);
}
