.mat-mdc-slider {
  &.cmsslider {
    .mdc-slider__value-indicator {
      background-color: var(--purple600) !important;
    }

    .mdc-slider__track--active_fill,
    .mdc-slider__thumb-knob {
      border-color: var(--purple600) !important;
    }

    .mdc-slider__value-indicator:before {
      border-top-color: var(--purple600) !important;
    }
  }
}

.mat-mdc-slider:not([disabled]) .mat-mdc-slider-visual-thumb:not(.mdc-slider__thumb--with-indicator) .mdc-slider__thumb-knob {
  scale: 0.85 !important;
  top: 45% !important;
  left: 45% !important;
}
